body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

h1.page-title {
    color: #a00517;
    font-family: Bangers;
}

#container {
    font-family: 'Arial';
    overflow: hidden;
}

.navbar {
    margin-bottom: 0px;
}

#content {
    height: 100%;
    width: 100%;
}

#main {
    display: block;
    margin: 0 auto;
    width: 100%;
    position: relative;
    background-color: #0054a6;
    padding-bottom: 40px;
    padding-top: 40px;
}

.videoframe {
    margin: auto;
    width: 70%;
    background: lightblue;
    height: 500px;
    border: 3px solid #73AD21;
    margin-top: 60px;
    margin-bottom: 30px;

}

body {
    width: 100%;
    margin: 0px;
    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
    display: block;
}

h1 {
    color: #2a2a2a;
    font-size: 46px;
    display: block;
    font-weight: bold;
}

.top {
    width: 100%;
    height: 90px;
    z-index: 9999;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-top: 5px solid #0054a6;
    display: block;
    margin-top: 0px;
}

.top-container {
    width: 970px;
    margin: 0 auto;
    display: block;
}

.logo {
    margin-top: 12px;
    margin-left: 20px;
}

.top-text {
    float: right;
    margin: auto 0;
}

.video-part {
    background-color: #fff;
    padding: 20px;
}

.count-down {
    position: relative;
    width: 100%;
    height: 60px;
}

.count-down h4 {
    font-size: 28px;
    color: #0054a6;
    font-weight: bold;
}

.instruction {
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
}

.instruction u {
    font-weight: 700;
}

.UserTestimonials h3 {
    font-weight: bold;
}

.UserTestimonials .nothingToShow {
    background-color: transparent !important;
}

.UserTestimonials ul {
    list-style-type: none;
    padding-left: 0px;
}

.UserTestimonials ul li {
    padding: 0px !important;
}

.user-web {
    /*font-size: 18px;*/
}

.user-web p {
    font-size: 18px;
    margin: 20px 10px 10px 10px;
}

.form-control.title {
    font-size: 46px;
    font-weight: bold;
    color: #2a2a2a;
    display: block;
    text-align: center;
}

.white-space-pre {
    white-space: pre-wrap;
}

.none {
    display: none;
}

.header {
    margin-top: 0px;
    font-family: Comic Sans MS;
}

#footer {
    text-align: center;
    margin: auto;
    bottom: 0px;
    width: 100%;
    height: 120px; /* Height of the footer */
    background: #2a2a2a;
    padding: 25px;
    color: #c8c8c8;
    display: flex;
    justify-content: center;
    align-content: center;
}

.newfoot {
    bottom: 0px;
}

.social-btn {
    margin-top: 10px;
}

.edit-mode-bar {
    padding: 5px;
    text-align: center;
}

li .well {
    text-align: left;
}

.editmode {
    position: absolute;
    width: 150%;
    background: #FFF;
    padding: 30px;
    border: 1px solid #CCC;
    left: -25%;
    box-shadow: 0 0 12px rgba(0, 0, 0, .5);
}

.WebinarList {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 300px;
}

.panel-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.panel-body .well {
    margin-bottom: 0;
}

.panel-body li {
    position: relative;
    border-bottom: 1px dotted rgba(0, 0, 0, .1);
}

.panel-body li .glyphicon-pencil {
    position: absolute;
    right: 5px;
    top: 5px;
}

.panel-body li .glyphicon-remove {
    position: absolute;
    right: 35px;
    top: 5px;
}

.glyphicon-plus {
    float: right;
    color: #000;
    font-size: 12px;
}

.testimonials {
    background-color: #DFDCDC;
    padding-top: 20px;
    padding-bottom: 20px;
}

.nothingToShow {
    padding: 20px;
    background-color: rgba(0, 0, 0, .05);
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    border-radius: 6px;
}

ul.social-list li {
    display: inline-block;
    float: left;
    border-bottom: none;
}